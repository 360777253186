import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";

const TwoColumnContentWithTopImage = ({
	heading,
	tagline,
	content,
	btnCta1,
	btnCta2,
	image,
	bgColor,
	textColor = "#fff",
	border = "16px",
}) => {
	return (
		<div>
			<section
				className="d-none d-md-block"
				style={bgColor && sectionStyle(bgColor)}
			>
				<div className="position-relative">
					<Container className="position-relative">
						{image && image.sourceUrl && (
							<Row className="mx-lg-6">
								<Col>
									<GatsbyImage
										image={image?.localFile.childImageSharp.gatsbyImageData}
										alt={image?.altText}
										className="rounded-5"
										style={{
											maxHeight: "640px",
											height: "auto",
											width: "100%",
											border: `${border} solid #666`,
										}}
									/>
								</Col>
							</Row>
						)}
					</Container>
					<div
						className="border-top border-1 border-white py-7 position-absolute w-100"
						style={{ backgroundColor: bgColor, bottom: "-80px" }}
					>
						<Container>
							<Row>
								<Col lg={6} className="mb-4 mb-lg-0 text-start pe-lg-6">
									{tagline && (
										<span style={{ color: textColor }}>{tagline}</span>
									)}
									<h1 className="my-0  pt-2 h2" style={{ color: textColor }}>
										{heading}
									</h1>
								</Col>
								<Col lg={6}>
									<div
										className="pb-4"
										dangerouslySetInnerHTML={{ __html: content }}
										style={{ color: textColor }}
									></div>
									{((btnCta1 && btnCta1.url) || (btnCta2 && btnCta2.url)) && (
										<div className="d-flex align-items-center">
											{btnCta1 && btnCta1.url && (
												<Button
													as={Link}
													href={btnCta1.url}
													size="lg"
													className="px-4 py-3 me-3 montserrat-bold fs-6"
													target={btnCta1.target}
													variant="secondary"
												>
													{btnCta1.title}
												</Button>
											)}
											{btnCta2 && btnCta2.url && (
												<Button
													as={Link}
													href={btnCta2.url}
													size="lg"
													className="px-4 py-3 montserrat-bold fs-6 bg-transparent border-white text-white"
													target={btnCta2.target}
												>
													{btnCta2.title}
													<IoIosArrowForward className="fs-4 ms-2" />
												</Button>
											)}
										</div>
									)}
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</section>
			<section
				className="d-md-none"
				style={bgColor && sectionStyleMobile(bgColor)}
			>
				<div className="position-relative">
					<div
						className="border-top border-1 border-white py-4  w-100"
						style={{ backgroundColor: bgColor }}
					>
						<Container>
							<Row>
								<Col lg={6} className="mb-4 mb-lg-0 text-start pe-lg-6">
									{tagline && (
										<span style={{ color: textColor }}>{tagline}</span>
									)}
									<h2 className="my-0  pt-2 " style={{ color: textColor }}>
										{heading}
									</h2>
								</Col>
								<Col lg={6}>
									<div
										className="pb-4"
										dangerouslySetInnerHTML={{ __html: content }}
										style={{ color: textColor }}
									></div>
									{((btnCta1 && btnCta1.url) || (btnCta2 && btnCta2.url)) && (
										<div className="d-flex align-items-center">
											{btnCta1 && btnCta1.url && (
												<Button
													as={Link}
													href={btnCta1.url}
													size="lg"
													className="px-4 py-3 me-3 montserrat-bold fs-6"
													target={btnCta1.target}
													variant="secondary"
												>
													{btnCta1.title}
												</Button>
											)}
											{btnCta2 && btnCta2.url && (
												<Button
													as={Link}
													href={btnCta2.url}
													size="lg"
													className="px-4 py-3 montserrat-bold fs-6 bg-transparent border-white text-white"
													target={btnCta2.target}
												>
													{btnCta2.title}
													<IoIosArrowForward className="fs-4 ms-2" />
												</Button>
											)}
										</div>
									)}
								</Col>
							</Row>
						</Container>
					</div>
					<Container className="position-relative pb-4">
						{image && image.sourceUrl && (
							<Row className="mx-lg-6">
								<Col>
									<GatsbyImage
										image={image?.localFile.childImageSharp.gatsbyImageData}
										alt={image?.altText}
										className="rounded-5"
										style={{
											maxHeight: "640px",
											height: "auto",
											width: "100%",
											border: `${border} solid #666`,
										}}
									/>
								</Col>
							</Row>
						)}
					</Container>
				</div>
			</section>
		</div>
	);
};

export const sectionStyle = (bgColor) => ({
	marginTop: "-105px",
	padding: "210px 0 80px",
	backgroundColor: bgColor,
});

export const sectionStyleMobile = (bgColor) => ({
	paddingTop: "105px",
	marginTop: "-105px",
	backgroundColor: bgColor,
});

export default TwoColumnContentWithTopImage;
