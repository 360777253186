import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const CertificationSteps = ({
	heading,
	bgImage,
	btnCta1,
	certificationItems,
}) => {
	return (
		<section className="py-8" style={sectionWrapper(bgImage)}>
			<Container>
				<Row className={`align-items-lg-center justify-content-center`}>
					<Col lg={10} className="mb-4 mb-lg-0 text-white">
						<div className={`text-center`}>
							<h2 className="my-auto text-white m-0">{heading}</h2>
						</div>
					</Col>
				</Row>
				<Row className="d-flex rounded-3 justify-content-center mt-6 certification-step-wrapper position-relative">
					{certificationItems &&
						certificationItems.map((item, i) => (
							<Col
								xl={3}
								className={`pe-0 pe-lg-3 p-3 certification-step position-relative certification-step-${
									i % 2 == 0 ? "even" : "odd"
								}`}
								key={i}
							>
								<div
									className={`bg-white rounded-circle d-flex align-items-center justify-content-center certification-step-image`}
								>
									<div
										className="p-3 text-center"
										style={{
											maxWidth: "90%",
											overflow: "hidden",
											borderRadius: "50%",
										}}
									>
										<img
											style={{ maxWidth: "90%" }}
											src={item.certificationItemLogo?.node.sourceUrl}
											alt={item.certificationItemLogo?.node?.altText}
										/>
									</div>
								</div>
								<div
									className="text-white p-3 pb-0 p-lg-4 rounded-5 position-absolute certification-step-content"
									style={{
										border: `2px solid ${item.certificationItemColor}`,
										background: item.certificationItemBackgroundColor,
									}}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: item.certificationItemContent,
										}}
									></div>
								</div>
							</Col>
						))}
					<div class="vertical-dotted-line position-absolute d-xl-none  translate-middle-x"></div>
				</Row>
				{btnCta1 && (
					<Button
						as={Link}
						href={btnCta1.url}
						size="sm"
						className="px-4 py-3 mx-auto d-block montserrat-bold fs-6 mt-6"
						target={btnCta1.target}
						variant="secondary"
						style={{ width: "fit-content" }}
					>
						{btnCta1.title}
					</Button>
				)}
			</Container>
		</section>
	);
};

export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(11, 64, 50, 0.95) 0%, rgba(11, 64, 50, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export default CertificationSteps;
